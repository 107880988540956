import Cookies from "universal-cookie";

export function submitRegistration(values, state, component) {
  const cookies = new Cookies();
  const token = cookies.get("X-CSRF-Token");
  let new_values = JSON.parse(JSON.stringify(values))
  component.setState({
    loading: true,
  })
  // if(component.isPressOrAnalyst()=="Press"){
  //   new_values.user.topicsofinterestpress = values.user.topicsofinterest.join(",")
  //   new_values.user.topicsofinterest=""
  // }else {
  //   new_values.user.topicsofinterestpress=""
  //   new_values.user.topicsofinterest = values.user.topicsofinterest.join(",")
  // }

  fetch(`/registration`, {
    method: `POST`,
    redirect: "manual",
    body: JSON.stringify(new_values),
    headers: {
      "X-CSRF-Token": token,
      "Content-Type": 'application/json'
    }
  }).then(response => {
    try {
      return response.json();
    }
    catch(e) {
      component.setState({
        loading: false,
        error: "An error occurred. Please try again later."
      })
    }
  })
  .then(json => {
    if (json.error == null) {
      window.scrollTo(0,0);
      component.setState({
        page: 4,
        loading: false,
      })
    } else {
      component.setState({
        fullPageError: json.error,
        loading: false,
      })
    }
  });
}
