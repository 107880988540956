import React from "react";
import { Formik, Field, Form } from "formik";
import Select from 'react-select'
import { submitRegistration } from "./RegistrationUtility"
import {seatPreferences, topicsOfInterest, sessionFormatOptions, sessionTopicsOptions, arrivalDates, returnDates,theaterOptions, activities, preferredRoomType, travelInformation, getApparelSizeOptions,specialAccomodations, dietaryRestrictions, getCountrySelectOptions, getStateSelectOptions, getTimezoneOptions, getTimezoneInfo, getJobTypes, getPronounOptions}  from "./RegistrationOptions"
import RegistrationPageSignifier from "./partials/RegistrationPageSignifier"
class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forceAttendeeType: null,
      page: 1,
      pageOneError: null,
      pageThreeError: null,
      pageTwoError: null,
      checkError: false,
      emailTaken: false,
      saving: false,
      features: props.features,
      participant: props.participant
    }
  }

  renderTester() {
    const { participant } = this.props;
    if(participant.test_flag && (participant.email||"").indexOf("@jaguardesignstudio") != -1) {
      const pages = [
        {id: 1},
        {id: 2},
        {id: 3},
        {id: 4},
      ];

      const types = [
        {id: "Press"},
        {id: "Analyst"}
      ];
      return (
        <div className="sg-reg-page-swapper">
          {pages.map(x=>(
            <div onClick={()=>this.setState({
              page: x.id
            })}className="sg-reg-page-swapper-item">
              Go To Page {x.id}
            </div>
          ))}
          {types.map(x=>(
            <div onClick={()=>this.setState({
              forceAttendeeType: x.id
            })}className="sg-reg-page-swapper-item">
              Change to {x.id}
            </div>
          ))}
        </div>
      )
    }
  }

  pageHiddenClass(renderedPage) {
    const { page } = this.state;
    if(renderedPage == page) {
      return "visible";
    }
    return "hidden";
  }

  isEmailPersonal(email) {
    return false;
  }

  includesPolyfill(string1, string2) {
    return string1.indexOf(string2) != -1
  }

  isPageThreeValid(formikProps) {
    const user = formikProps.values.user;
    if(
      user.waiverandrelease != "True" ||
      user.termsandconditions != "True" ||
      user.userdataagreement != "True"
    ) {
      return false;
    }
    // if(
    //   this.requiresDataProtectionAcknowledgement(formikProps)
    //   &&
    //   user.dataprotectionacknowledgement != "True"
    // ) {
    //   return false
    // }
    return true
    //TODO
  }

  isPageTwoValid(formikProps) {
    const { fields, participant } = this.props;
    const { values } = formikProps;
    const { user } = values;
    let fieldList = [];

    if(this.isPressOrAnalyst() == "Press") {
      fieldList = fieldList.concat(["topicsinterestedpress"])
    } else {
      // fieldList = fieldList.concat(["questionsforexecutives"])
      // fieldList = fieldList.concat(["extendedprogramming"])
    }

    if (this.showHotel()) {
      fieldList = fieldList.concat(["ciscoreservehotel"])
    }

    if (this.showFlight()) {
      fieldList = fieldList.concat(["requireairtransportation"])
      if (user.requireairtransportation === "Yes") {
        if (user.consentsensitiveinformation == null) {
          return false
        }
        fieldList = fieldList.concat([
          "transportfromairport",
          "passportfirstname",
          "passportlastname",
          "departuredate",
          "departuretime",
          "departureairport",
          "returnairport",
          "returndate",
          "returntime",
          "seatpreference",
        ])
      }
    }

    if(this.isInternational(formikProps)) {
      if(user.embassyrequireinvitation == null) {
        return false
      }
    }


    fieldList = fieldList.filter((x,i,t)=>t.indexOf(x) == i);
    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    console.log({
      fieldList,
      validFields,
      fL: fieldList.length,
      vF: validFields.length
    })

    if (!(validFields.length == fieldList.length)) {
      return false;
    }
    //TODO
    return true;
  }


  isPageOneValid(formikProps) {
    //TODO
    const { fields } = this.props;
    const { values } = formikProps;
    let fieldList = [
      "name_first",
      "name_last",
      "work_phone",
      "email",
      "company",
      "job_title",
      "address_street_1",
      "address_country",
      "address_city",
      "address_postcode",
      "ecname",
      "ecnumber",
    ];
    if(!this.isInternational(formikProps)) {
      fieldList= fieldList.concat([
          "address_state"
      ])
    }
    if(values.user.specialaccomodations.indexOf("Other")!=-1) {
      fieldList = fieldList.concat(['specialaccomodationsother'])
    }
    if(values.user.dietarypreference.indexOf("Other")!=-1) {
      fieldList = fieldList.concat(['dietarypreferenceother'])
    }


    const validFields = fieldList.filter(field => values["user"][field] && values["user"][field].length > 0);
    if (!(validFields.length == fieldList.length)) {
      return false;
    }

    return true;
  }

  renderInterests(formikProps) {
    const { checkError } = this.state;
    // <div className="col-xs-12">
    //   <label className="required">
    //     {/* {this.isPressOrAnalyst() == "Press" ? (
    //       "To help us plan, please select your top 3–4 topics from the list below."
    //     ) : "Please select your top three Roundtable topics:"} */}
    //     Please select your top three Roundtable topics:
    //   </label>
    // </div>
    // {this.renderChooseThree('topicsofinterest', formikProps, topicsOfInterest(this.isPressOrAnalyst()))}
    return (
      <div className="row" style={{display: "flex", flexWrap: "wrap"}}>
        <div className="col-xs-12 sg-header-copy">
          Your Interests
        </div>
          {this.isPressOrAnalyst() == "Press" ?
            <div className="col-xs-12">
              <label className="required">
                What are the top three topics you are interested in discussing at Cisco Partner Summit 2023?
              </label>
              {this.renderField('topicsinterestedpress',formikProps, false, {component: 'textarea'})}
            </div>
            :
            <>
              <div className="col-xs-12">
                <label className="">
                  What topics would you like to discuss at Cisco Partner Summit 2023?
                </label>
                {this.renderField('othertopics',formikProps, false, {not_required: true, component: 'textarea'})}
              </div>
              <div className="col-xs-12">
                <label className="">
                  Please list up to three (3) questions for our Executive Leadership Team.
                </label>
                {this.renderField('questionsforexecutives',formikProps, false, {not_required: true, component: 'textarea'})}
              </div>
            </>
          }
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
  }

  renderCustomerAppreciation(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          Cisco Live Celebration
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          We invite you to also attend the Cisco Live Celebration on Wednesday, June 7th from 7:30&nbsp;p.m. until 11:00&nbsp;p.m. at Allegiant Stadium.
          <br/>
          <br/>
          Would you like to attend? *
        </div>
        <div className="col-xs-12">
          {this.renderCheckbox('customerappreciationevent',formikProps, false, {
            forceValue:"True",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('customerappreciationevent',formikProps, false, {
            forceValue:"False",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.customerappreciationevent == null ? "Please select an option" : ""}
          </div>
        </div>
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )

  }

  renderTravelInformation(formikProps) {
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          Travel Information
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          <b>Official Arrival Date:</b> Monday, November 6th.
          <br/>
          <i>The first formal business activity begins on Tuesday, November 7th at 7:00 a.m.</i>
          <br/>
          <br/>
          <div>
          <b>Official Departure Dates:</b> Wednesday, November&nbsp;8th or Thursday, November&nbsp;9th.
          <br/>
          <i>The last formal business activity concludes on Wednesday, November&nbsp;8th at 5:00 p.m. Anyone attending Cisco Partner Summit Celebrations may depart on Thursday, November&nbsp;9th.</i>
          {/* <br />
          <br />
          Exceptions:
          <br />
          <i>* Anyone attending CX Day extended programming may depart on Wednesday, November&nbsp;8th after 5:00 p.m.</i>
          <br />
          <i>** Anyone attending Cisco Partner Summit Celebrations may depart on Thursday, November&nbsp;9th.</i> */}

          </div>

        </div>
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
  }

  renderHotelAccomodations(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
        Hotel Accommodations
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          Cisco is pleased to provide you with accommodations at the Loews Miami Beach hotel.
        </div>
        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          Would you like Cisco to book and pay for your hotel reservations at Loews Miami Beach hotel on your behalf?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('ciscoreservehotel',formikProps, false, {
            forceValue:"Yes",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('ciscoreservehotel',formikProps, false, {
            forceValue:"No",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.ciscoreservehotel == null ? "Please select an option" : ""}
          </div>
        </div>
        {values.user.ciscoreservehotel == "Yes" ? (
          <div className="col-xs-12 gc-registration-copy">
            <br/>
            We will pay for your accommodations at the{" "}
            <a
              href="https://www.loewshotels.com/miami-beach"
              target="_blank"
            >
              Loews Miami Beach Hotel
            </a> (including rate and tax).
            <br/>
            <br/>
            Up to three (3) nights accommodation will be covered by Cisco over the official program nights (November 6th, 7th, & 8th, with departure on the 9th).
            <br/>
            <br/>
            Upon check-in, you will be asked to present a credit card. You are liable for any additional room nights and associated incidentals outside of the official program dates.
            <br/>
            <br/>
            If you need to make changes to your hotel accommodation, please contact <a href="mailto:ciscopress-analyst@cisco.com">ciscopress-analyst@cisco.com</a>.
          </div>
        ):""}
        {values.user.ciscoreservehotel == "No" ? (
          <div className="col-xs-12 gc-registration-copy">
            <br/>
            If your travel plans change, and you need assistance with hotel accommodations please reach out to <a href="mailto:ciscopress-analyst@cisco.com">ciscopress-analyst@cisco.com</a>.
          </div>
        ):""}
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
  }

  renderFlightAccomodations(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    // if(!(this.isPressOrAnalyst()=="Press" && !this.isInternational(formikProps))) {
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          Flight Accommodations & Ground Transportation
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          Cisco is pleased to provide you with air transportation to Cisco Partner Summit 2023.
        </div>
        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          Do you require air transportation?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('requireairtransportation',formikProps, false, {
            forceValue:"Yes",
          })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
          {this.renderCheckbox('requireairtransportation',formikProps, false, {
            forceValue:"No",
          })}<label className="checkbox-label"> No </label>
          <div className="registration-field-error">
            {checkError && !this.isPageTwoValid(formikProps) && values.user.requireairtransportation == null ? "Please select an option" : ""}
          </div>
        </div>
        {this.renderRequiredFlightAccomodations(formikProps)}
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
    // }
  }
  renderRequiredFlightAccomodations(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    if(values.user.requireairtransportation == "Yes") {
      return (
        <>
         <div className="col-xs-12 gc-registration-copy">
            <br/>
            Cisco will book and pay for your flight to/from Miami International Airport (MIA). Cisco will also book and pay for ground transportation to/from Loews Miami Beach Hotel.
            <br/>
          </div>

          <div className="col-xs-12 gc-registration-copy required">
            <br/>
            Do you require ground transportation to/from Miami International Airport (MIA)?
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('transportfromairport',formikProps, false, {
              forceValue:"Yes",
            })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
            {this.renderCheckbox('transportfromairport',formikProps, false, {
              forceValue:"No",
            })}<label className="checkbox-label"> No </label>
            <div className="registration-field-error">
              {checkError && !this.isPageTwoValid(formikProps) && values.user.transportfromairport == null ? "Please select an option" : ""}
            </div>
          </div>

          <div className="col-xs-12">
            <br/>
            Please complete the following fields so we may begin to book your flight reservations to Miami, FL (MIA). After completing your registration, a representative from American Express/Cisco Travel will contact you within 5-7 business days of receiving your information.
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required">Your First Name as it Appears on Your Passport</label>
            {this.renderField('passportfirstname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className=""> Your Middle Name as it Appears on Your Passport</label>
            {this.renderField('passportmiddlename',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12 col-sm-4">
            <label className="required"> Your Last Name as it Appears on Your Passport</label>
            {this.renderField('passportlastname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Arrival Day </label>
            {this.renderSelectField('departuredate',formikProps, arrivalDates(formikProps))}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">Preferred Arrival Time</label>
            {this.renderField('departuretime',formikProps, false, {type: "time"})}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Preferred Departure Airport</label>
            {this.renderField('departureairport',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">Preferred Returning Airport</label>
            {this.renderField('returnairport',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Return Date</label>
            {this.renderSelectField('returndate',formikProps, returnDates(formikProps))}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">Preferred Return Time</label>
            {this.renderField('returntime',formikProps, false, {type: "time"})}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">Seat Preference</label>
            {this.renderSelectField('seatpreference',formikProps, seatPreferences(),false)}
          </div>
          <div className="col-xs-12"/>

          <div className="col-xs-12 col-sm-6">
            <label className="">Frequent Flyer Airline</label>
            {this.renderField('frequentyflyerairline',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="">Frequent Flyer Number</label>
            {this.renderField('frequentyflyermembership',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12">
            <label className="">If you have any special transportation needs, please note them here</label>
            {this.renderField('specialtransportationneeds',formikProps, false, {not_required: true, component: "textarea"})}
          </div>

          <div className="col-xs-12 gc-registration-copy">
            <br/>
            Do you consent to the use of the information provided here for the purpose of booking travel and accommodations for the Press and Analyst Conference at Cisco Partner Summit 2023?
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('consentsensitiveinformation',formikProps, false, {
              forceValue:"True",
            })}<label className="checkbox-label"> Yes, I consent. </label>
            <div className="registration-field-error">
              {checkError && !this.isPageTwoValid(formikProps) && values.user.consentsensitiveinformation !="True" ? "Please agree to continue" : ""}
            </div>
          </div>
        </>
      )
    }
  }

  departureDates() {
    return [
      "Wednesday, November 2",
      "Thursday, November 3"
    ].map(x=>{return{value:x, label: x}})
  }

  renderPartnerSummitWaiver(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          Terms & Conditions
        </div>
        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          Please indicate that you have read and agree to the <a href="/registration/CISCO_PS23_Assumption_Of_Risk_And_Waiver_Of_LiabiIity_And_Indemnity_Agreement.pdf" target="_blank">Risk and Waiver of Liability</a>.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('waiverandrelease',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>I have read and agree to the Cisco Press and Analyst Conference Waiver and Release.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.waiverandrelease != "True" ? "Please click to agree." : ""}
          </div>
        </div>

        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          Please indicate that you have read and agree to <a href="/registration/CISCO_PS23_Code_Of_Conduct_And_Terms_And_Conditions.pdf" target="_blank">Cisco’s Terms & Conditions</a>.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('termsandconditions',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>I have read and agree to the Cisco Press and Analyst Conference Terms and Conditions.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.termsandconditions != "True" ? "Please click to agree." : ""}
          </div>
        </div>

        <div className="col-xs-12 gc-registration-copy required">
          <br/>
          Please indicate that you have read and agree to the <a href="/registration/CISCO_PS23_Data_Use_Agreement.pdf" target="_blank">User Data Agreement</a>.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('userdataagreement',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>I have read and I acknowledge that my data will be processed according to the User Data Agreement and the <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#privacy-manager" target="_blank">Cisco Online Privacy Statement</a>.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.userdataagreement != "True" ? "Please click to agree." : ""}
          </div>
        </div>
        {/* <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div> */}
      </div>
    )
  }

  isInternational(formikProps) {
    const { participant } = this.props;
    if((participant['tags']||[]).map(x=> x['name']).indexOf('Force International') != -1) {
      return true
    }
    if((participant['tags']||[]).map(x=> x['name']).indexOf('Force Local') != -1) {
      return false
    }

    return formikProps.values.user.address_country != "United States";
  }

  isPressOrAnalyst() {
    //todo
    const { forceAttendeeType } = this.state;
    const { participant } = this.props;
    if(forceAttendeeType) {
      return forceAttendeeType;
    }
    return participant.type;
  }

  showHotel() {
    const { participant } = this.props;
    return participant.tags.filter(tag => tag.name === "No Hotel").length === 0
  }
  showFlight() {
    const { participant } = this.props;
    return participant.tags.filter(tag => tag.name === "No Flight").length === 0
  }

  renderVisaInformation(formikProps) {
    const { checkError } = this.state;
    const { values } = formikProps;
    if(this.isInternational(formikProps)) {
      return (
        <div className="row">
          <div className="col-xs-12 sg-header-copy">
            Visa Information
          </div>
          <div className="col-xs-12 gc-registration-copy required">
            <br/>
            Does your Embassy require a letter of invitation to obtain a United States Visa?
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('embassyrequireinvitation',formikProps, false, {
              forceValue:"True",
            })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
            {this.renderCheckbox('embassyrequireinvitation',formikProps, false, {
              forceValue:"False",
            })}<label className="checkbox-label"> No </label>
            <div className="registration-field-error">
              {checkError && !this.isPageTwoValid(formikProps) && values.user.embassyrequireinvitation == null ? "Please select an option" : ""}
            </div>
          </div>
          {values.user.embassyrequireinvitation == "True" ? (
            <>
              <div className="col-xs-12 gc-registration-copy">
                A representative from Cisco Partner Summit team will contact you within 2 business days.
              </div>
            </>
          ):""}
        </div>
      )
    }
  }

  renderPageTwo(formikProps) {
    const { participant, fields } = this.props;
    const { checkError, fullPageError, pageTwoError } = this.state;
    const { values } = formikProps;

    return (
      <div className={`registration-page-one ${this.pageHiddenClass(2)}`}>
        {this.renderInterests(formikProps)}

        {/* {this.isPressOrAnalyst() == "Analyst" ?
          <div className="row" style={{display: "flex", flexWrap: "wrap"}}>
            <div className="col-xs-12 sg-header-copy">
              Cisco CX Day
            </div>
            <div className="col-xs-12">
              <label className="required">
                Will you participate in CX Day extended programming?
              </label>
            </div>
            <div className="col-xs-12">
              {this.renderCheckbox('extendedprogramming',formikProps, false, {
                forceValue:"Yes",
              })}<label className="checkbox-label" style={{marginRight:"20px"}}> Yes </label>
              {this.renderCheckbox('extendedprogramming',formikProps, false, {
                forceValue:"No",
              })}<label className="checkbox-label"> No </label>
              <div className="registration-field-error">
                {checkError && !this.isPageTwoValid(formikProps) && values.user.extendedprogramming == null ? "Please select an option" : ""}
              </div>
            </div>
            <div className="col-xs-12 gc-registration-copy">
              As a key part of Cisco’s overall transformation, Cisco CX is helping customers realize the value of their investments both directly and through our partners. We invite you to extend your engagement with the Press and Analyst Conference at Partner Summit by participating in CX-focused sessions on <b>Wednesday afternoon from 2:00 p.m. – 5:00 p.m.</b> CX leadership will share organizational updates, customer stories, and solicit your valuable insight through interactive dialogue.
            </div>
            <div className="col-xs-12">
              <div className="att-proam-content-divider"/>
            </div>
          </div>
          :
          <></>
        } */}

        {this.renderTravelInformation(formikProps)}
        {/* {this.renderCustomerAppreciation(formikProps)} */}
        {
          this.showHotel() ?
          this.renderHotelAccomodations(formikProps)
          :
          <></>
        }
        {
          this.showFlight() ?
          this.renderFlightAccomodations(formikProps)
          :
          <></>
        }
        {this.renderVisaInformation(formikProps)}
        {/* {this.renderPartnerSummitWaiver(formikProps)} */}
        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageTwoValid(formikProps) ? pageTwoError : ""}
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPageOne(formikProps) {
    const { fields, participant } = this.props;
    const { values } = formikProps;
    const { user } = values;
    const { pageOneError, emailTaken, checkError, pageThreeError, fullPageError } = this.state;
    return (
      <>
      <div className={`registration-page-one ${this.pageHiddenClass(1)}`}>
        <div className="row">
          <div className="col-xs-12 sg-header-copy">
            Your Information
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required">First Name</label>
            {this.renderField('name_first',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Last Name</label>
            {this.renderField('name_last',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Preferred First Name on Badge (optional) </label>
            {this.renderField('preferredname',formikProps, false, {not_required: true})}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className=""> Preferred Pronoun(s) on Badge (optional) </label>
            {this.renderSelectField('preferredpronoun',formikProps, getPronounOptions(), false, {not_required: true})}

          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Mobile Telephone (including area and country code)</label>
            {this.renderField('work_phone',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required" style={{paddingTop: "22px"}}> Email Address </label>
            {this.renderField('email',formikProps,false,{disabled: true})}
            {emailTaken ? (
              <div className="registration-field-error">
                 This email has already been taken.
              </div>
            ): ""}
          </div>
          {/* <div className="col-xs-12"/> */}
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Firm Name </label>
            {this.renderField('company',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Job Title </label>
            {this.renderField('job_title',formikProps)}
          </div>
          <div className="col-xs-12">
          </div>
          {this.renderAddressForm(formikProps)}
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-header-copy">
            Emergency Contact
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency Contact Name </label>
            {this.renderField('ecname',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Emergency Contact Phone Number</label>
            {this.renderField('ecnumber',formikProps)}
          </div>

          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-header-copy">
            Accommodations (multi-select)
          </div>
          <div className="col-xs-12 gc-registration-copy" style={{marginBottom: "5px"}}>
            <br/>
            Do you require additional accommodations?
          </div>
          <div className="col-xs-12">
            {/* <label className=""> Additional accommodations </label> */}
            <div className="row">
              {specialAccomodations(fields).map(x=>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  {this.renderAdditiveCheckbox('specialaccomodations',x.value, x.label, formikProps)}
                </div>
              )}
              {values.user.specialaccomodations.indexOf('Other') != -1 ? (
                <div className="col-xs-12">
                  <label className="required"> Please specify</label>
                  {this.renderField('specialaccomodationsother',formikProps, false)}
                </div>
              ) : ""}
            </div>
          </div>
          <div className="col-xs-12"></div>
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-header-copy">
            Dietary preferences (multi-select)
          </div>
          <div class="col-xs-12 gc-registration-copy" style={{marginBottom: "5px"}}>
            <br/>
            Cisco Partner Summit will be incorporating the following dietary preferences into the main menus: Vegetarian, Wheat-Free, Vegan, Dairy-Free, and Wheat-Free/Dairy-Free. All selections will be clearly labeled at every meal to indicate if they are vegetarian, vegan, and/or contain dairy, eggs, fish, shellfish, tree nuts, peanuts, wheat, soybeans, sesame or pork. If you have any of the dietary preferences listed below please let us know.
          </div>
          <div className="col-xs-12">
            <div className="row">
              {dietaryRestrictions(fields).map(x=>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  {this.renderAdditiveCheckbox('dietarypreference',x.value, x.label, formikProps)}
                </div>
              )}
              {values.user.dietarypreference.indexOf('Food Allergies') != -1 || values.user.dietarypreference.indexOf('Other') != -1 ? (
                <div className="col-xs-12">
                  <label className="required"> Please specify</label>
                  {this.renderField('dietarypreferenceother',formikProps, false)}
                </div>
              ) : ""}
            </div>

          </div>
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageOneValid(formikProps) ? pageOneError : ""}
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

  renderChooseThree(name, formikProps, options) {
    const { checkError } = this.state;
    const { values } = formikProps;
    const { user } = values;
    const optionsRendered = options.map(x=>
      <div className="col-xs-12 col-sm-6">
        {this.renderChooseThreeCheckbox(name, x.label, formikProps)}
      </div>
    )
    return (
      <>
        {optionsRendered}
        {user[name].indexOf("Other") != -1 ? (
          <div className="col-xs-12">
            {this.renderField(`${name}other`,formikProps, false, {
              component: "textarea",
              placeholder: "If other, what topics are you interested in?"
            })}
          </div>
        ):""}
        {this.isPressOrAnalyst()=="Press" && checkError && (user[name]||[]).length < 3 ? (
          <div className="col-xs-12">
            <div className="registration-field-error" style={{marginTop: 0}}>
            Please select 3-4 options
            </div>
          </div>
        ):""}
        {this.isPressOrAnalyst()!="Press" && checkError && (user[name]||[]).length < 2 ? (
          <div className="col-xs-12">
            <div className="registration-field-error" style={{marginTop: 0}}>
            Please select 2–3 options
            </div>
          </div>
        ):""}
      </>
    )
  }

  renderAdditiveCheckbox(name,value, label, formikProps) {
    const { values } = formikProps;
    const { user } = values;
    let checked = false;
    const renderOptions = {}
    let nameValues = []
    try {
      nameValues = user[name]
      checked = nameValues.indexOf(value) != -1
    }catch(e){

    }
    return(
      <>
      {/* <br/> */}
      <img
        src={ checked  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          console.log({checked, nameValues});
          if(checked) {
            user[name] = nameValues.filter(x=>x != value)
          } else {
            user[name] = nameValues.concat([value])
          }
          formikProps.setFieldValue('user', user)
        }}
      />
        <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>{label}</label>
      </>
    )
  }

  renderChooseThreeCheckbox(name,label, formikProps) {
    const { values } = formikProps;
    const { user } = values;
    let checked = false;
    const renderOptions = {}
    try {
      checked = user[name].indexOf(label) != -1
    }catch(e){

    }
    return(
      <>
      <img
        src={ checked  ? "/images/checkbox-checked-1.svg" : "/images/checkbox-unchecked-1.svg" }
        style={{cursor:"pointer", marginBottom: "7px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(checked) {
            user[name] = (user[name]||[]).filter(x=>x != label);
          }else {
            let maxCount = 3
            if(this.isPressOrAnalyst()=="Press"){
              maxCount = 4
            }
            if((user[name]||[]).length < maxCount){
              user[name] = (user[name]||[]).concat([label])
            }
          }
          formikProps.setFieldValue('user', user)
        }}
      />
        <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>{label}</label>
      </>
    )
  }

  renderGuestFields(formikProps) {
    const { values } = formikProps;
    if(values.user.bringingguest == "True") {
      return (
        <>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest First Name </label>
            {this.renderField('guestnamefirst',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Last Name</label>
            {this.renderField('guestnamelast',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Email</label>
            {this.renderField('guestemail',formikProps)}
          </div>
          <div className="col-xs-12 col-sm-6">
            <label className="required"> Guest Apparel Size </label>
            {this.renderSelectField('guestapparelsize',formikProps, getApparelSizeOptions())}
          </div>
        </>
      )
    }
  }

  renderAddressForm(formikProps) {
    const { values } = formikProps;
    const { user } = values;
    return(
      <>
      <div className="col-xs-12 col-sm-6">
        <label className="required"> Address 1 </label>
        {this.renderField('address_street_1',formikProps)}
      </div>
      <div className="col-xs-12 col-sm-6">
        <label className=""> Address 2 (optional) </label>
        {this.renderField('address_street_2',formikProps, false, {not_required: true})}
      </div>
      <div className="col-xs-12 col-sm-6">
        <label className="required"> Country </label>
        {this.renderSelectField('address_country',formikProps, getCountrySelectOptions())}
      </div>
      {user.address_country=="United States" ? (
        <div className="col-xs-12 col-sm-6">
          <label className="required"> State </label>
          {this.renderSelectField('address_state',formikProps, getStateSelectOptions())}
        </div>
      ) : (
        <div className="col-xs-12 col-sm-6">
          <label className=""> Province/County (Optional) </label>
          {this.renderField('address_state',formikProps, false, {not_required: true})}
        </div>
      )}

      <div className="col-xs-12 col-sm-6">
        <label className="required"> City </label>
        {this.renderField('address_city',formikProps)}
      </div>

      <div className="col-xs-12 col-sm-6">
        <label className="required"> Postal Code </label>
        {this.renderField('address_postcode',formikProps)}
      </div>
      </>
    )
  }


  renderField(name,formikProps, customValidation = false, properties = {}) {
    const { checkError } = this.state;
    let erroring = checkError && (formikProps.values.user[name] == undefined || formikProps.values.user[name].length <= 0 || customValidation);
    if(properties['not_required']) {
      erroring = false || customValidation;
    }
    return (
      <Field
        disabled={properties['disabled']}
        onKeyUp={() => properties['onKeyUp'](formikProps)}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`}
        type={properties['type']}
        name={`user[${name}]`}
        placeholder={properties['placeholder']||""}
      />
    )
  }

  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['user'][name])[0]
    }catch (e) {

    }
    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.value
          formikProps.setFieldValue('user', user)
        }}
         isClearable={false}
        clearable={false}
        filterOption={this.customFilter}
      />
    )
  }

  renderMultiSelectField(name, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = !renderOptions['not_required'] && checkError && ((formikProps.values.user[name] == null || formikProps.values.user[name].length <= 0));
    let value = [];
    try {
       value = (formikProps.values['user'][name]||"").split(";").filter(x=>x).map(x=>{return {label: x, value: x}});
    }catch (e) {

    }
    return (
      <Select
        className={erroring ? "registration-select-erroring" : ""}
        options={options}
        classNamePrefix='registration-select'
        defaultValue={{value:"Unassigned",label:renderOptions['placeholder']||""}}
        value={options ? value : ""}
        onChange={(option) => {
          const { user } = formikProps.values;
          user[name] = option.map(x=>x.value).join(";")
          formikProps.setFieldValue('user', user)
        }}
        clearable={false}
        isMulti={true}
        filterOption={this.customFilter}
      />
    )
  }

  renderCheckbox(name, formikProps, customValidation=false, renderOptions={}) {
    let value = false;
    try {
      value = formikProps.values['user'][name] == "True";
    }catch(e){

    }
    if(renderOptions['forceValue'] != undefined ) {
      value = formikProps.values['user'][name] == renderOptions['forceValue']
    }
    let images = {
      unchecked: "/images/checkbox-unchecked-1.svg",
      checked: "/images/checkbox-checked-1.svg"
    }
    if(renderOptions.checkedImage) {
      images.checked = renderOptions.checkedImage
    }
    if(renderOptions.uncheckedImage) {
      images.unchecked = renderOptions.uncheckedImage
    }
    return(
      <img
        src={ value  ? images.checked : images.unchecked }
        style={{cursor:"pointer", marginBottom: "7px", height: "21px"}}
        onClick={(e) => {
          const { user } = formikProps.values;
          if(renderOptions['forceValue'] != undefined) {
            user[name] = renderOptions['forceValue']
          }else {
            user[name] = value ? "False" : "True"
          }

          formikProps.setFieldValue('user', user)
        }}
      />
    )
  }

  customFilter(option, searchText) {
    const ones =  option.label.toLowerCase().split(" ").filter(x=> x.startsWith(searchText.toLowerCase()));
    return ones.length > 0;
  }

  renderCovidAttendeeProtocol(formikProps) {
    const { values } = formikProps;
    const { checkError } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          COVID-19 Attendee Protocol
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          View the{" "}
          <a href="https://www.ciscolive.com/global/attend/attendee-info/health-safety.html" target="_blank">
            Health & Safety Policies
          </a>
          <br/>
          <br/>
          By registering to attend this event in-person, I acknowledge Health & Safety Policies are subject to change, and I agree to abide by the Health & Safety Policies required by Cisco and/or the event venue at the time of the event. I understand I may be denied access to, or removed from, the event if I do not comply.*
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('covidattendeeprotocol',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}> Yes, I agree.</label>
          <div className="registration-field-error">
            {checkError && !this.isPageThreeValid(formikProps) && values.user.covidattendeeprotocol != "True" ? "Please click to agree." : ""}
          </div>
        </div>
        <div className="col-xs-12">
          <div className="att-proam-content-divider"/>
        </div>
      </div>
    )
  }

  renderLatestOffersAndPromotions(formikProps) {
    const { values } = formikProps;
    const { checkError } = this.state;
    return (
      <div className="row">
        <div className="col-xs-12 sg-header-copy">
          Latest Offers and Promotions
        </div>
        <div className="col-xs-12 gc-registration-copy">
          <br/>
          Cisco would like to use your information above to provide you with the latest offers, promotions, and news regarding Cisco products and services. You can unsubscribe at any time.
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('ciscoemailuseconsent',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I would like to be contacted by email.</label>
        </div>

        <div className="col-xs-12 gc-registration-copy">
          <br/>
          Do you consent to the use of your registration information by event sponsors to contact you with offers, promotions, and news about their products and services?
        </div>
        <div className="col-xs-12">
          <br/>
          {this.renderCheckbox('sponsoremailuseconsent',formikProps)}
          <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>Yes, I consent.</label>
        </div>
      </div>
    )
  }

  requiresDataProtectionAcknowledgement(formikProps) {
    //TODO only china and Korea
    const { values } = formikProps;
    const { user } = values;
    const { address_country } = user;
    if(address_country == "China" || address_country == "South Korea") {
      return true;
    }
    return false;
  }
  renderDataProtection(formikProps) {
    const { values } = formikProps;
    const { checkError } = this.state;
    if(this.requiresDataProtectionAcknowledgement(formikProps)){
      return (
        <div className="row">
          <div className="col-xs-12">
            <div className="att-proam-content-divider"/>
          </div>
          <div className="col-xs-12 sg-header-copy">
            Cisco Data Protection Acknowledgement
          </div>
          <div className="col-xs-12">
            <br/>
            {this.renderCheckbox('dataprotectionacknowledgement',formikProps)}
            <label className="checkbox-label" for="topic_ids" style={{verticalAlign:"middle", lineHeight:"19px"}}>
              I agree that Cisco may process my personal information in accordance with its <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#privacy-manager" target="_blank">Online Privacy Statement</a>.
              </label>
            <div className="registration-field-error">
              {checkError && !this.isPageThreeValid(formikProps) && values.user.dataprotectionacknowledgement != "True" ? "Please click to agree." : ""}
            </div>
          </div>
        </div>
      )
    }
  }

  renderPageThree(formikProps) {
    const { participant } = this.props;
    const { checkError, pageThreeError, fullPageError} = this.state;
    return (
      <div className={`registration-page-one ${this.pageHiddenClass(3)}`}>
        {this.renderPartnerSummitWaiver(formikProps)}
        {/* {this.renderCovidAttendeeProtocol(formikProps)} */}
        {/* {this.renderDataProtection(formikProps)} */}
        {/* {this.renderLatestOffersAndPromotions(formikProps)} */}
        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="registration-field-error text-center">
              {checkError && !this.isPageThreeValid(formikProps) ? pageThreeError : ""}
              {fullPageError}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPageFour(formikProps) {
    const { participant } = this.props;
    return (
      <div className={`registration-page-one ${this.pageHiddenClass(4)}`}>
        <div className="row">
          <div className="col-xs-12 att-proam-registration-section-copy">
            If you have any questions regarding the registration process, please contact
            <br/>
            <a href="mailto:ciscopress-analyst@cisco.com">ciscopress-analyst@cisco.com</a>.
            <br/>
            <br/>
            <div className="">
              <br/>
              <br/>
              <a target="_blank" href="mailto:ciscopress-analyst@cisco.com">
                <button type="button" style={{paddingTop: "10px", marginBottom: "30px"}} className="sg-round-button sg-primary-button">
                  Contact
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderHeader(formikProps) {
    const { page } = this.state;
    return (
      <div className="att-proam-registration-header">
        <div className="sg-header-copy">
          {
            page == 4 ?
            <>Thank you for registering for the Press and Analyst Conference at Cisco Partner Summit 2023. We are delighted that you will join us.</>
            :
            <>We look forward to welcoming you to the Press and Analyst Conference at Cisco Partner Summit 2023.</>
          }
        </div>
        {page == 1 ? (
          <div className="sg-copy">
            <br/>
            Please complete the following form. Once the completed form has been submitted, you will receive a confirmation email. If you have any questions regarding the registration process, please contact <a href="mailto: ciscopress-analyst@cisco.com">ciscopress-analyst@cisco.com</a>.
          </div>
        ):""}
        <RegistrationPageSignifier page={page} changePage={()=>{console.log("TODO:Change page")}}/>
      </div>
    )
  }

  renderButtons(formikProps) {
    const { page } = this.state;
    if(page < 4) {
      return (
        <div className="registration-page-one visible">
          <div className="row">
            <div className="col-xs-12">
              <br/>
            </div>
            <div className="col-xs-6">
              {page > 1 ? (
                <button onClick={()=>this.prevPage(formikProps)} className="sg-round-button sg-secondary-button">
                  Previous Step
                </button>
              ):""}
            </div>
            <div onClick={()=>this.nextPage(formikProps)} className="col-xs-6 text-right">
              <button className="sg-round-button sg-primary-button">
                {page == 3 ? "Register" : "Continue"}
              </button>
            </div>
            <div className="col-xs-12">
              <br/>
              <br/>
            </div>
          </div>
        </div>

      )
    }
  }

  nextPage(formikProps = {}){
  const { page }  = this.state;
  switch(page) {
    case 1:
      if(!this.isPageOneValid(formikProps)){
        this.setState({
          pageOneError: "Please make sure all fields have been properly filled in",
          checkError: true,
        })
        return;
      }
      break;
    case 2:
      if(!this.isPageTwoValid(formikProps)){
        this.setState({
          pageTwoError: "Please make sure all fields have been properly filled in",
          checkError: true,
        })
        return;
      }
      break;
    case 3:
      if(!this.isPageThreeValid(formikProps)){
        this.setState({
          pageThreeError: "Please make sure all fields have been properly filled in",
          checkError: true
        })
        return;
      }else {
        formikProps.submitForm();
        return;
      }
      break;
    default:
      break;
  }
  window.scrollTo(0,0);
  this.setState({
    page: page + 1,
    checkError: false,
    pageTwoError: null,
    pageOneError: null,
    pageThreeError: null,
  })
}

  prevPage(formikProps){
    const { page }  = this.state;
    window.scrollTo(0,0);
    this.setState({
      page: page - 1,
      fullPageError: null,
      checkError: false
    })
  }

  render() {
    const { user, participant} = this.props;
    return (
      <div className="registration-form">
        {this.renderTester()}
        <Formik
          initialValues={{
            user: {
              name_first: participant ? participant.name_first : "",
              name_last: participant ? participant.name_last : "",
              preferredname: "",
              preferredpronoun: "",
              work_phone: "",
              email: participant ? participant.email : "",
              company: user ? user.company : "",
              job_title: user ? user.job_title : "",
              address_street_1: participant ? participant.address_street_1 : "",
              address_street_2: participant ? participant.address_street_2 : "",
              address_country: participant ? participant.address_country : "",
              address_state: participant ? participant.address_state : "",
              address_city: participant ? participant.city : "",
              address_postcode: participant ? participant.address_postcode : "",
              ecname:"",
              ecnumber:"",
              specialaccomodations: [],
              specialaccomodationsother: "",
              dietarypreference: [],
              dietarypreferenceother:"",

              topicsinterestedpress:"",
              questionsforexecutives: "",
              othertopics: "",
              ciscoreservehotel: null,
              requireairtransportation: null,
              transportfromairport: null,
              passportfirstname:"",
              passportmiddlename:"",
              passportlastname:"",
              departuredate:"",
              departuretime:"",
              departureairport:"",
              returnairport:"",
              returndate:"",
              returntime:"",
              seatpreference:"",
              frequentyflyerairline:"",
              frequentyflyermembership:"",
              specialtransportationneeds:"",
              consentsensitiveinformation:null,
              embassyrequireinvitation: null,
              waiverandrelease: null,
              termsandconditions: null,
              userdataagreement: null,
              
              covidattendeeprotocol: null,
              dataprotectionacknowledgement:null,
              ciscoemailuseconsent: null,
              sponsoremailuseconsent: null,
            },
          }}
          onSubmit={(values, actions) => {
            submitRegistration(values, this.state, this)
          }}
          render={(formikProps) => (
            <Form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }} id="user-registration-form" style={{border: "0"}}>
              {this.renderHeader(formikProps)}
              {this.renderPageOne(formikProps)}
              {this.renderPageTwo(formikProps)}
              {this.renderPageThree(formikProps)}
              {this.renderPageFour(formikProps)}
              {this.renderButtons(formikProps)}
            </Form>
        )}/>
      </div>
    );
  }
}

export default RegistrationForm;
