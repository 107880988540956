import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      success: false,
      date: null,
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/decline`,(json)=>{
      if(json && json.error == null){
        this.setState({
          success: true,
          date: json.date,
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }


  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className="att-proam-registration-email-validation-copy">
          <div class="att-proam-registration-section-header">Sorry we’ll miss you</div>
          <br/>
          Thank you for your response. We are sorry you are unable to attend the Press and Analyst Conference at Cisco Partner Summit 2023. We look forward to welcoming you at a future Cisco event.
          <br/>
          <br/>
          If you have any questions, please contact the <a href="mailto: executiverelations@cisco.com">Cisco Executive Relations Team</a>.
          <br/>
          <br/>
          <div className="text-left">
            <a target="_blank" href="mailto:executiverelations@cisco.com">
              <button type="button" style={{paddingTop: "10px"}} className="sg-round-button sg-primary-button">
                Contact Us
              </button>
            </a>
          </div>
        </div>
      )
    }
    return (
      <form src="#" onSubmit={this.declineEmail} className="att-proam-registration-email-validation registration-form text-left" style={{textAlign:"left", padding:"20px 0px"}}>
        <div>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-header text-left">
              Sorry we’ll miss you.
            </div>
            <div className="col-xs-12 att-proam-registration-section-copy text-left" style={{maxWidth:"600px"}}>
            Thank you for your response. We are sorry you are unable to attend the Press and Analyst Conference at Cisco Partner Summit 2023. To confirm your response, please provide your email address below.
            <br/>
            <br/>
            We look forward to welcoming you at a future Cisco event.
              <br/>
            </div>
          </div>
        </div>
        <div className="att-proam-registration-email-validation-fields">
          <br/>
          <label>Enter Email Address*</label>
          <input
            value={emailValue}
            onChange={(e) => this.setState({
              emailValue: e.target.value
            })}
            className="form-control"
          />
        </div>
        {error ? (
          <div className="att-proam-registration-email-validation-error">
            {error}
          </div>
        ):""}
        <div className="att-proam-registration-email-validation-submit">
          <br/>
          <br/>
          <button
            type="button"
            onClick={this.declineEmail}
            className="sg-round-button sg-primary-button"
          >
            Continue
          </button>
        </div>
        <div>
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-section-copy text-left">
              <br/>
              If you have any questions, please contact the <a href="mailto: executiverelations@cisco.com">Cisco Executive Relations Team</a>.
              <br/>
              <br/>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default RegistrationDecline;
